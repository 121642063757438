/* stylelint-disable */
var pcFlg = true, tabletFlg = false, spFlg = false
if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPod') !== -1 || navigator.userAgent.indexOf('iPad') !== -1 || (navigator.userAgent.indexOf('Android') !== -1)) {
  pcFlg = false
  if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPod') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1)) {
    spFlg = true
  } else if (navigator.userAgent.indexOf('iPad') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') < 0)) {
    tabletFlg = true
  }
}

$(document).ready(function () {
  var flag = true
  $('.header_other_menu').click(function () {
    if (flag) {
      $('.nav_overlay').fadeIn()
      $(this).parent().next('.header_nav').addClass('o-open')
      flag = false
    }
  })
  $('.header_nav_close').click(function () {
    if (!flag) {
      $('.nav_overlay').fadeOut()
      $(this).parents('.header_nav').removeClass('o-open')
      flag = true
    }
  })

  $('.js-navigation').click(function () {
    $('.header_nav_slide_wrapper').css('zIndex', -2)
    $('.js-navigation').next('.header_nav_slide_wrapper').slideUp(300)
    $('.js-navigation').removeClass('o-current')
    if ($(this).next('.header_nav_slide_wrapper').css('display') === 'none') {
      if (!spFlg) {
        $('.nav_overlay').fadeIn()
      }
      $(this).addClass('o-current')
      $(this).next('.header_nav_slide_wrapper').css('zIndex', -1)
      $(this).next('.header_nav_slide_wrapper').slideDown(500)
    } else {
      if (!spFlg) {
        $('.nav_overlay').fadeOut()
      }
      $(this).removeClass('o-current')
      $(this).next('.header_nav_slide_wrapper').slideUp(300)
    }
  })
  $('.js-navigation_close').click(function () {
    $('.nav_overlay').fadeOut()
    $(this).parents('.header_nav_slide_wrapper').prev('.header_nav_list_anchor').removeClass('o-current')
    $(this).parents('.header_nav_slide_wrapper').slideUp(300)
  })

  if (pcFlg || tabletFlg) {
    $(window).scroll(function () {
      var h = $(window).scrollTop()
      if (h > 180) {
        $('.header_wrapper').addClass('fix')
        setTimeout(function () {
          $('.header_wrapper').addClass('show')
        }, 5)
      } else {
        $('.header_wrapper').removeClass('fix show')
      }
    })
  }

  if (pcFlg || tabletFlg) {
    $(window).scroll(function () {
      var h = $(window).scrollTop()
      if (h > 400) {
        $('.float_conversion').addClass('o-show')
      } else {
        $('.float_conversion').removeClass('o-show')
      }
    })
  }
})
